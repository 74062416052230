import React from 'react';
import './App.css';
import ButtonComponent from './components/ButtonComponent';
import StatusDisplay from './components/StatusDisplay';
import useServerStatus from './hooks/useServerStatus';  // Make sure the path is correct

function App() {
    const apiUrl = 'https://ev8cvl8sqd.execute-api.us-east-1.amazonaws.com/prod/manage-ec2'; // API URL
    const apiKey = process.env.REACT_APP_API_KEY;  // Replace with your actual API key
    
    const { serverStatus, isButtonDisabled, handleServerAction } = useServerStatus(apiUrl, apiKey);

    const getStatusColor = () => {
        switch (serverStatus) {
            case 'running':
                return 'button-red';
            case 'pending':
            case 'stopping':
                return 'button-yellow';
            case 'stopped':
                return '';
            default:
                return 'button-grey';
        }
    };

    const buttonClass = getStatusColor();

    return (
        <div className="App">
            <header className="App-header">
                <h1>Server Name: Hoosiers-Survive</h1>
                <StatusDisplay status={serverStatus} />
                <ButtonComponent
                    onClick={() => handleServerAction(serverStatus === 'running' ? 'stop' : 'start')}
                    disabled={isButtonDisabled}
                    buttonClass={buttonClass}
                >
                    {serverStatus === 'running' ? 'Stop Server' : 'Start Server'}
                </ButtonComponent>
            </header>
        </div>
    );
}

export default App;

