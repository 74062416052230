import { useState, useEffect, useCallback } from 'react';

const useServerStatus = (apiUrl, apiKey) => {
    const [serverStatus, setServerStatus] = useState('Unknown');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const fetchServerStatus = useCallback(async () => {
        setIsButtonDisabled(true);
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey
                },
                body: JSON.stringify({ action: 'describe' })
            });
            if (!response.ok) {
                throw new Error('Failed to fetch server status');
            }
            const data = await response.json();
            setServerStatus(data.status);
        } catch (error) {
            console.error('Error fetching server status:', error);
            setServerStatus('Error');
        }
        setIsButtonDisabled(false);
    }, [apiUrl, apiKey]); // Dependencies of useCallback

    useEffect(() => {
        fetchServerStatus();
    }, [fetchServerStatus]); // Dependency on the memoized function

    const handleServerAction = async (action) => {
        if (serverStatus === 'pending' || serverStatus === 'stopping') {
            console.log("Action currently not allowed due to server status.");
            return;
        }
        setIsButtonDisabled(true);
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey
                },
                body: JSON.stringify({ action })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            await fetchServerStatus(); // Re-fetch status to update UI
        } catch (error) {
            console.error('Error sending server action:', error);
        } finally {
            setIsButtonDisabled(false);
        }
    };

    return { serverStatus, isButtonDisabled, handleServerAction };
};

export default useServerStatus;
